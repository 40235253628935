import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FAFAFA',
      neutralLight3: '#F2F0F0',
      neutralLight2: '#F2F2F2',
      neutralLight1: '#EDE9E2',
      neutralDark6: '#303030',
      neutralDark5: '#272626',
      neutralDark4: '#272727',
      neutralDark3: '#262626',
      neutralDark2: '#161615',
      neutralDark1: '#000000',
      primaryDark: '#338B9B',
      primaryLight: '#3E9FB1',
      danger: '#ED0F36',
    },
  },
  fontFamily: {
    heading: "'Fraunces', serif",
    paragraph: "'Manrope', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
